import i18n from 'i18next';
import { initReactI18next } from 'react-i18next';
import en from './en.json';
import zhTw from './zhTw.json';

i18n
  .use(initReactI18next) // passes i18n down to react-i18next
  .init({
    resources: {
      en: {
        translation: en,
      },
      zhTw: {
        translation: zhTw,
      },
    },
    lng: localStorage.getItem('lang') || 'en',
    fallbackLng: 'en', // use en if detected lng is not available
    saveMissing: true, // send not translated keys to endpoint
    keySeparator: false, // we do not use keys in form messages.welcome
    interpolation: {
      escapeValue: false, // react already safes from xss
    },
  });
