import React, { useState, useEffect, useRef } from 'react';
// import { useHistory, RouteComponentProps } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
// components
import CornButton, {
  types as buttonTypes,
  sizes
} from '@/components/cornButton';
// images
// import accountIcon from '@/assets/images/icon-account@2x@2x.png';
// styles
// import './index.scss';

/**
 * popup 類型
 * @readonly
 * @enum {number}
 * @param {string} default - default
 */
export enum types {
  default,
  alert
}

export interface popupProps {
  title?: string;
  show?: boolean;
  showTitle?: boolean;
  subTitle?: string;
  showCancel?: boolean;
  onComfirm?: Function;
  onCancel?: Function;
  onOverlay?: Function;
  closeOnComfirm?: boolean;
  closeOnCancel?: boolean;
  closeOnOverlay?: boolean;
  onClose?: Function;
  className?: string;
  type?: types;
  children?: any;
}

const Footer = () => null;

/**
 * @component PopupDialog
 * @function PopupDialog - Renders a <PopupDialog /> component
 * @param {object} props - component 參數
 * @param {boolean} props.show - 是否顯示此彈出視窗
 * @param {string} props.title - 標題
 * @param {boolean} props.showTitle - 顯示標題
 * @param {boolean} props.showCancel - 顯示取消按鈕
 * @param {string} props.subTitle - 標題下小字
 * @param {Function} props.onComfirm - 按下確定時觸發
 * @param {Function} props.onCancel - 按下取消時觸發
 * @param {Function} props.onOverlay - 按下後方遮罩時觸發
 * @param {boolean} props.closeOnComfirm - 是否在按下確定時關閉，預設開啟
 * @param {boolean} props.closeOnCancel - 是否在按下取消時關閉，預設開啟
 * @param {boolean} props.closeOnOverlay - 是否在按下後方遮罩時關閉，預設開啟
 * @param {Function} props.onClose - 關閉時觸發
 * @param {string} props.className - class
 * @param {types} props.type - popup 類型,, 請用 enum PopupDialog.types
 */
const PopupDialog: React.FC<popupProps> & {
  // 欄位
  Footer?: any;
  // 類型
  types: typeof types;
} = ({
  title = '',
  show: propShow = true,
  showTitle = true,
  subTitle,
  showCancel = true,
  onComfirm,
  onCancel,
  onOverlay,
  closeOnComfirm = true,
  closeOnCancel = true,
  closeOnOverlay = true,
  onClose,
  className,
  type = types.default,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  const overlayRef = useRef(null);

  const footerChildren = Array.isArray(children)
    ? children.find((el: any) => el.type === Footer)
    : null;

  const [show, setShow] = useState(false);

  const errorTransitioHandler = () => {};

  const onTransitionHandler = (e: any) => {
    const { target } = e;
    if (typeof onClose === 'function' && !show) {
      onClose(e);
    }
  };

  const callClose = () => {
    setShow(false);
  };

  const onComfirmHandler = (e: any) => {
    // console.log(`debug -> onComfirmHandler -> onComfirm`, onComfirm);
    if (typeof onComfirm === 'function') {
      onComfirm({ event: e, callClose });
    }
    if (closeOnComfirm) {
      callClose();
    }
  };

  const onCancelHandler = (e: any) => {
    if (typeof onCancel === 'function') {
      onCancel({ event: e, callClose });
    }
    if (closeOnCancel) {
      callClose();
    }
  };

  const onOverlayHandler = (e: any) => {
    const { target } = e;
    if (target !== overlayRef.current) {
      return;
    }
    if (typeof onOverlay === 'function') {
      onOverlay({ event: e, callClose });
    }
    if (closeOnOverlay) {
      callClose();
    }
  };

  useEffect(() => {
    // setTimeout(() => {
    //   setShow(propShow);
    // }, 0);
    setShow(propShow);
  }, [propShow]);

  return (
    <div
      className={[
        'corn-popup-overlay',
        `is-${types[type]}-type`,
        ...(show ? ['show'] : ['hide']),
        ...(className ? [className] : [])
      ].join(' ')}
      onClick={onOverlayHandler}
      onTransitionEnd={onTransitionHandler}
      ref={overlayRef}
    >
      <div className='corn-popup-container'>
        {showTitle ? (
          <h3 className='corn-popup-title'>{title || t('msgNotice')}</h3>
        ) : null}
        {showTitle && typeof subTitle === 'string' ? (
          <h3 className='corn-popup-sub'>{subTitle}</h3>
        ) : null}
        <div className='corn-popup-content'>{children ? children : null}</div>
        {footerChildren ? (
          <div className='corn-popup-bottom'>
            {footerChildren.props.children}
          </div>
        ) : (
          <div className='corn-popup-bottom'>
            {showCancel ? (
              <CornButton
                text={t('btnCancel')}
                className='corn-popup-button'
                size={sizes.middle}
                type={buttonTypes.cancel}
                onClick={onCancelHandler}
              />
            ) : null}
            <CornButton
              text={t('btnConfirm')}
              className='corn-popup-button'
              size={sizes.middle}
              type={
                type === types.alert ? buttonTypes.danger : buttonTypes.default
              }
              onClick={onComfirmHandler}
            />
          </div>
        )}
      </div>
    </div>
  );
};

// 欄位
PopupDialog.Footer = Footer;

PopupDialog.types = types;

export default PopupDialog;
