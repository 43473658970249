import React from 'react';
import { useTranslation } from 'react-i18next';

// components
import Toast, { toastType } from './toast';
export type { toastType } from './toast';

// images
import { ReactComponent as IconSuccess } from '@/assets/icon-success.svg';

// styles
import './index.scss';

// generic function type
type genericFunction<P> = {
  bivarianceHack(prop?: P): void;
}['bivarianceHack'];

export interface propType {}

export interface attachmentsType {
  toast: genericFunction<propType>;
  success: genericFunction<propType>;
  fail: genericFunction<propType>;
}
/**
 * @description 每 render 一個 <Popup /> component 就會 push 一個新增 popup 的方法進 callList 並暴露給 module 外使用
 */
const callList: Function[] = [];

const ToastContainer: React.FC<propType> & attachmentsType = () => {
  const { t } = useTranslation();

  const [list, setList] = React.useState<propType[]>([]);

  const removeDialog = (index: number) => {
    list.splice(index, 1);
    setList([...list]);
  };

  const callPopup = (params: propType) => {
    return new Promise<any>((resolve, reject) => {
      setList([
        ...list,
        {
          ...params,
          onClose: resolve,
        },
      ]);
    });
  };
  callList.splice(0);
  callList.push(callPopup);

  React.useEffect(() => {
    return () => {
      const index = callList.findIndex((func) => func === callPopup);
      if (index !== -1) {
        callList.splice(index, 1);
      }
    };
  }, []);

  return (
    <div
      className={['ng-toast-container', ...(list.length ? ['show'] : ['hide'])]
        .join(' ')
        .trim()}
    >
      {list.map((params: any, index) => (
        // @ts-ignore
        <Toast
          key={`toast-${index}`}
          // onOverlay={() => {}}
          {...{
            ...params,
            onClose: () => {
              if (typeof params?.onClose === 'function') {
                params?.onClose();
              }
              removeDialog(index);
            },
          }}
        />
      ))}
    </div>
  );
};

export const toast = (props: propType | string = {}): Promise<any> => {
  let result: Promise<any> | undefined;
  callList.forEach((func) => {
    if (typeof func === 'function') {
      result = func(
        typeof props === 'string'
          ? {
              title: props,
            }
          : props
      );
    }
  });
  return typeof result !== 'undefined'
    ? result
    : Promise.reject('no activable popup');
};

export const success = (props: propType | string = {}): Promise<any> => {
  return toast({
    ...(typeof props === 'string'
      ? {
          title: props,
        }
      : props),
    ...{ type: toastType.success },
  });
};
export const fail = (props: propType | string = {}): Promise<any> => {
  return toast({
    ...(typeof props === 'string'
      ? {
          title: props,
        }
      : props),
    ...{ type: toastType.fail },
  });
};

ToastContainer.toast = toast;
ToastContainer.success = success;
ToastContainer.fail = fail;

export default ToastContainer;
