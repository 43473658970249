import React, { lazy } from 'react';
import {
  HashRouter,
  Switch,
  Route,
  Redirect,
  RouteProps,
  useHistory,
  matchPath
} from 'react-router-dom';

// containers
import Home from '@/containers/home';

// components
import Popup, { popup } from '@/components/popups';
import ToastContainer from '@/components/toast';

// const Home = lazy(() => import('./containers/home'));
// const NotFound = lazy(() => import('./containers/notFound'));

function Router(...params: any[]) {
  return (
    // <HashRouter>
    //   <Switch>
    //     <Route path='/' exact component={Home} />
    //   </Switch>
    // </HashRouter>
    <Home />
  );
}

export default function Context() {
  return (
    <>
      <Router />
      <Popup />
      <ToastContainer />
    </>
  );
}
