import React, { useState, useEffect } from 'react';
import { useTranslation } from 'react-i18next';
// styles
import './index.scss';

/**
 * 按鈕類型
 * @readonly
 * @enum {number}
 */
export enum types {
  default,
  cancel,
  danger,
  dark
}

/**
 * 按鈕大小
 * @readonly
 * @enum {number}
 */
export enum sizes {
  big,
  middle,
  small,
  default,
  content
}

/**
 * @component CornButton
 * @function CornButton - Renders a <CornButton /> component
 * @param {object} props - component 參數
 * @param {string} props.text - button 文字
 * @param {size} props.size - 按鈕大小, 可選, 請用 enum CornButton.sizes
 * @param {types} props.type - 按鈕類型, 可選, 請用 enum CornButton.types
 * @param {string|boolean} props.disabled - 按鈕可用狀態
 * @param {string} props.icon - icon 圖片路徑
 * @param {string} props.className - class
 * @param {string} props.placeholder - input placeholder
 * @param {function} props.onClick - button onClick
 */
const CornButton: React.FC<{
  text?: string;
  size?: sizes;
  type?: types;
  disabled?: string | boolean;
  icon?: string | any;
  className?: string;
  placeholder?: string;
  onClick?: Function;
}> & {
  types: typeof types;
  sizes: typeof sizes;
} = ({
  text,
  size = sizes.default,
  type = types.default,
  disabled,
  className,
  icon,
  placeholder,
  onClick,
  children,
  ...restProps
}) => {
  const { t } = useTranslation();

  const changeHandler = (e: any) => {
    if (typeof onClick === 'function') {
      onClick(e);
    }
  };

  return (
    <button
      className={[
        'corn-button-container',
        className,
        `is-${types[type]}-type`,
        `is-${sizes[size]}-size`
      ].join(' ')}
      disabled={!!disabled}
      onClick={changeHandler}
    >
      {typeof icon === 'string' ? (
        <img className='corn-button-icon' src={icon} />
      ) : (
        icon
      )}
      {children ? (
        children
      ) : (
        <span className='corn-button-text'>{text ?? t('btnConfirm')}</span>
      )}
    </button>
  );
};

CornButton.types = types;
CornButton.sizes = sizes;

export default CornButton;
