import React, { useState, useEffect } from 'react';
// import { useHistory, RouteComponentProps } from 'react-router-dom';
// components
export { default as PopupDialog, types as PopupTypes } from './dialog';
export type { popupProps } from './dialog';
import PopupDialog, { popupProps } from './dialog';
// images
// import accountIcon from '@/assets/images/icon-account@2x@2x.png';
// styles
import './index.scss';

/**
 * @description 每 render 一個 <Popup /> component 就會 push 一個新增 popup 的方法進 callList 並暴露給 module 外使用
 */
const callList: Function[] = [];

/**
 * @component Popup
 * @function Popup - Renders a <Popup /> component
 * @param {object} props - component 參數
 */
const Popup: React.FC & {
  // 元件
  PopupDialog?: React.FC;
  popup?: typeof popup;
  // popupProps?: typeof popupProps;
} = () => {
  const [list, setList] = useState<popupProps[]>([]);

  callList.push((params: popupProps) => {
    // console.log(`debug -> callList.push -> params`, params);
    return new Promise<any>((resolve, reject) => {
      const onComfirm = (e: any) => {
        if (typeof params.onComfirm === 'function') {
          params.onComfirm(e);
        }
        resolve(e);
      };
      const onCancel = (e: any) => {
        if (typeof params.onCancel === 'function') {
          params.onCancel(e);
        }
        reject(e);
      };
      const onOverlay = (e: any) => {
        if (typeof params.onOverlay === 'function') {
          params.onOverlay(e);
        }
        reject(e);
      };
      setList([
        ...list,
        {
          ...params,
          onComfirm,
          onCancel,
          onOverlay
        }
      ]);
    });
  });

  const removeDialog = (index: number) => {
    setList(list.filter((o, i) => i !== index));
    list.slice(index, 1);
  };

  return (
    <div
      className={[
        'corn-popup-group',
        ...(list.length ? ['show'] : ['hide'])
      ].join(' ')}
    >
      {list.map((params: any, index) => (
        // @ts-ignore
        <PopupDialog
          key={index}
          {...{
            onClose: () => {
              removeDialog(index);
            },
            ...params
          }}
        />
      ))}
    </div>
  );
};

/**
 * @function popup - 呼叫一個 popup
 * @param {popupProps} props - 要傳給 popup 的參數
 */
export const popup = (props: popupProps): Promise<any> => {
  let result: Promise<any> | undefined;
  callList.forEach((func) => {
    if (typeof func === 'function') {
      result = func(props);
    }
  });
  return typeof result !== 'undefined'
    ? result
    : Promise.reject('no activable popup');
};

Popup.popup = popup;
Popup.PopupDialog = PopupDialog;
// Popup.popupProps = popupProps;

export default Popup;
