import React, { useState, useEffect, useRef } from 'react';
import { useTranslation } from 'react-i18next';
import { useHistory } from 'react-router-dom';
import axios from 'axios';
// components
import inputField from '@/components/inputField';
import CornButton, {
  types as buttonTypes,
  sizes as buttonSizes
} from '@/components/cornButton';
import Popups, { PopupDialog, popup, PopupTypes } from '@/components/popups';
import Toast from '@/components/toast';
// images
import { ReactComponent as MetamaskIcon } from '@/assets/metamask.svg';
import { ReactComponent as DeleteIcon } from '@/assets/delete-button.svg';
import { ReactComponent as DiceIcon } from '@/assets/dice-button.svg';
import { ReactComponent as DonateIcon } from '@/assets/donate-icon.svg';

// styles
import './index.scss';

// @ts-ignore
window.axios = axios;

interface ticketType {
  numbers: number[];
  errorMsg: string;
}

const donate = '0x31C5c71e324895FA76523400eD4b3987F9E6117a';
const oneCake = '0xde0b6b3a7640000';
const max = 50;

const Home: React.FC = () => {
  const [loading, setLoading] = useState<string | boolean>(false);
  const [ticketNum, setTicketNum] = useState<number>(1);
  const [address, setAddress] = useState<string>('');
  const [list, setList] = useState<ticketType[]>([]);

  const onAddTicket = () => {
    const limit = max - list.length;
    Array(ticketNum < limit ? ticketNum : limit)
      .fill(0)
      .forEach(() => {
        list.push({
          numbers: Array(4)
            .fill(0)
            .map(() => Math.floor(1 + Math.random() * 14)),
          errorMsg: ''
        });
      });
    setList([...list.map((ticket) => ({ ...ticket }))]);
  };

  const handleAccounts = (address: string) => {
    if (typeof address !== 'string') {
      Toast.fail('錢包錯誤');
      setAddress('');
    } else {
      Toast.success({
        title: 'Connecting Successful!',
        content: `address: ${address}`
      });
      localStorage.setItem('ethereum_address', address);
      setAddress(address);
    }
  };

  const getAuthorization = () => {
    return new Promise<string[]>(async (resolve, reject) => {
      // @ts-ignore
      const { ethereum } = window ?? {};
      if (typeof ethereum?.request === 'function') {
        setLoading('授權中');
        ethereum
          .request({ method: 'eth_requestAccounts' })
          .then(resolve)
          .catch(async (error: any) => {
            setAddress('');
            setLoading(false);
            await Toast.fail(error?.message ?? '未知錯誤');
            reject('');
          });
      } else {
        setAddress('');
        await Toast.fail('MetaMask 未安裝');
        reject('');
      }
    });
  };

  const onMetaMask = async () => {
    const [address]: string[] = await getAuthorization();
    if (address) {
      handleAccounts(address);
    }
  };

  const onLogout = () => {
    localStorage.setItem('ethereum_address', '');
    setAddress('');
  };

  const requestAllowance = (num: number) => {
    // @ts-ignore
    const { ethereum } = window ?? {};
    const params = [
      {
        from: address,
        to: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
        gas: `0x${(43677).toString(16)}`, // 0.000218 BNB
        gasPrice: '0x12a05f200', // 5 gwei
        data: `0x095ea7b30000000000000000000000003c3f2049cc17c136a604be23cf7e42745edf3b91${Array(
          64
        )
          .fill('f')
          .join('')}`
      }
    ];
    return ethereum.request({
      method: 'eth_sendTransaction',
      params
    });
  };

  const checkAllowance = (num: number) => {
    return new Promise(async (resolve, reject) => {
      // @ts-ignore
      const { ethereum } = window ?? {};
      if (!address || typeof ethereum?.request !== 'function') {
        Toast.fail('錢包錯誤');
        setAddress('');
        reject(false);
        return;
      }
      // Toast.toast(address);
      setLoading('傳送中');
      // const params = [
      //   {
      //     from: address,
      //     to: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
      //     data: `0xdd62ed3e000000000000000000000000${address.replace(
      //       '0x',
      //       ''
      //     )}0000000000000000000000003c3f2049cc17c136a604be23cf7e42745edf3b91`
      //   }
      // ];
      // ethereum
      //   .request({
      //     method: 'eth_call',
      //     params
      //   })

      axios({
        url: 'https://bsc-dataseed.binance.org',
        method: 'POST',
        headers: {
          'Content-Type': 'application/json'
        },
        data: JSON.stringify({
          method: 'eth_call',
          params: [
            {
              to: '0x0e09fabb73bd3ade0a17ecc321fd13a19e81ce82',
              data: `0xdd62ed3e000000000000000000000000${address.replace(
                '0x',
                ''
              )}0000000000000000000000003c3f2049cc17c136a604be23cf7e42745edf3b91`
            },
            'latest'
          ],
          id: 49,
          jsonrpc: '2.0'
        })
      })
        .then(async (response: any) => {
          console.log(`debug -> .then -> response`, response);
          const result =
            response?.data?.result ?? response?.data ?? response ?? 0;
          const allowance = BigInt(result);
          console.log(`debug -> .then -> allowance`, allowance);
          // await Toast.toast(`${allowance}`);
          // const { length = 0 } = list ?? {};
          // requestAllowance(num).then(resolve);
          if (allowance > BigInt(oneCake) * BigInt(num)) {
            resolve(true);
          } else {
            requestAllowance(num)
              .then(resolve)
              .catch(async (error: any) => {
                setLoading(false);
                await Toast.fail({
                  title: `fail in requesting allowance`,
                  content: error?.message ?? '未知錯誤'
                });
                reject(false);
              });
          }
        })
        .catch(async (error: any) => {
          setLoading(false);
          await Toast.fail({
            title: `fail in checking allowance`,
            content: error?.message ?? '未知錯誤'
          });
          reject(false);
        });
    });
  };

  const handleSend = async (address: string) => {
    const { length = 0 } = list ?? {};
    const allowance = await checkAllowance(length);
    console.log(`debug -> handleSend -> allowance`, allowance);
    if (!allowance) {
      return;
    }
    // @ts-ignore
    const { ethereum } = window ?? {};
    if (
      typeof address !== 'string' ||
      typeof ethereum?.request !== 'function'
    ) {
      Toast.fail('錢包錯誤');
      setAddress('');
      return;
    }
    const cakes = length.toString(16);
    const cakesData = `${Array(64 - cakes.length)
      .fill('0')
      .join('')}${cakes}`; // x cakes
    const ticketsData = list
      .map(({ numbers }) =>
        numbers
          .map((num) => {
            const str = (num ?? 0).toString(16);
            return `${Array(64 - str.length)
              .fill('0')
              .join('')}${str}`;
          })
          .join('')
      )
      .join('');
    const data =
      '0x2bdff851' +
      `${Array(64 - oneCake.replace('0x', '').length)
        .fill('0')
        .join('')}${oneCake.replace('0x', '')}` +
      '0000000000000000000000000000000000000000000000000000000000000040' +
      cakesData +
      ticketsData;
    console.log(`debug -> data`, data);
    // 1: 0.00250074 BNB ($1.35)
    // 2: 0.00414312 BNB ($2.23)
    // 3: 0.00631861 BNB ($3.40)
    // 5: 0.0098867 BNB ($5.31)
    // 6: 0.011804295 BNB ($6.34)
    // 7: 0.013541935 BNB ($7.28)
    // 10: 0.019370055 BNB ($10.41)
    // 20: 0.038117505 BNB ($20.48)
    const params = [
      {
        from: ethereum.selectedAddress,
        to: '0x3c3f2049cc17c136a604be23cf7e42745edf3b91',
        gas: `0x${(210050 + 400000 * length).toString(16)}`, // 30400
        gasPrice: '0x12a05f200', // 5 gwei
        data
      }
    ];
    setLoading('傳送中');
    ethereum
      .request({
        method: 'eth_sendTransaction',
        params
      })
      .then(async (result: any) => {
        //@ts-ignore
        if (typeof window.gtag === 'function') {
          //@ts-ignore
          window.gtag('event', 'click', {
            event_category: 'cake lottery submitted',
            event_label: `${ethereum.selectedAddress}-${length}`
          });
        }
        await Toast.success({
          title: 'Transaction submitted!',
          content: result
        });
      })
      .catch(async (error: any) => {
        setLoading(false);
        await Toast.fail(error?.message ?? '未知錯誤');
      });
  };

  const onSend = async () => {
    const [address]: string[] = await getAuthorization();
    if (address) {
      handleSend(address);
    }
  };

  const copyToClipboard = (text: string) => {
    const input = document.createElement('input');
    document.body.appendChild(input);
    input.setAttribute('value', text);
    input.select();
    document.execCommand('copy');
    document.body.removeChild(input);
  };

  const onDonate = () => {
    copyToClipboard(donate);
    Toast.success({
      title: 'Donation Address Copied!',
      content: `${donate}`
    });
  };

  useEffect(() => {
    const address = localStorage.getItem('ethereum_address');
    if (address) {
      setAddress(address);
    }
    // @ts-ignore
    const { ethereum } = window ?? {};
    if (typeof ethereum?.on === 'function') {
      ethereum.on('accountsChanged', (accounts: Array<string>) => {
        console.log(`debug -> ethereum.on -> accounts`, accounts);
      });
    }
    // https://docs.metamask.io/guide/ethereum-provider.html#events
  }, []);

  useEffect(() => {
    setList([]);
  }, [address]);

  return (
    <div className='page home'>
      <div>Optional Numbers for PancakeSwap Lottery.</div>
      <div>This project is in beta. Use at your own risk.</div>
      {
        <div className='corn-metamask-container'>
          <CornButton
            icon={<MetamaskIcon />}
            type={buttonTypes.default}
            onClick={address ? onLogout : onMetaMask}
            text={
              address
                ? `Connected to: ${address
                    .split('')
                    .slice(0, 5)
                    .join('')}...${address
                    .split('')
                    .slice(address.length - 5, address.length)
                    .join('')}`
                : 'Connect MetaMask'
            }
          />
        </div>
      }
      {address && (
        <>
          <div className='corn-button-row'>
            <CornButton
              // icon={<MetamaskIcon />}
              className='corn-ticket-button'
              type={buttonTypes.cancel}
              size={buttonSizes.small}
              onClick={() => {
                setTicketNum(ticketNum < 2 ? 1 : ticketNum - 1);
              }}
              text={'-'}
            />
            <input
              className='corn-ticket-input'
              value={ticketNum}
              onChange={({ target: { value } }) => {
                setTicketNum(
                  Number.isNaN(+value) ? 1 : +value < 1 ? 1 : +value
                );
              }}
            />
            <CornButton
              // icon={<MetamaskIcon />}
              className='corn-ticket-button'
              type={buttonTypes.cancel}
              size={buttonSizes.small}
              onClick={() => {
                setTicketNum(ticketNum + 1);
              }}
              text={'+'}
            />
          </div>
          <div className='corn-button-row'>
            <CornButton
              // icon={<MetamaskIcon />}
              className='corn-add-button'
              // type={buttonTypes.default}
              type={
                list.length >= max ? buttonTypes.cancel : buttonTypes.default
              }
              size={buttonSizes.small}
              disabled={list.length >= max}
              onClick={onAddTicket}
              text={'ADD'}
            />
            {list.length ? (
              <CornButton
                // icon={<MetamaskIcon />}
                className='corn-remove-button'
                // type={buttonTypes.default}
                type={buttonTypes.danger}
                size={buttonSizes.small}
                onClick={() => {
                  setList([]);
                }}
                text={'REMOVE ALL'}
              />
            ) : null}
          </div>
          {list.map((ticket, row) => {
            const { numbers } = ticket;
            return (
              <div className='corn-ticket-row' key={`ticket-${row}`}>
                <span className='corn-ticket-number'>{`#${row + 1}`}</span>
                <DiceIcon
                  className='corn-ticket-dice'
                  onClick={() => {
                    const newRow = {
                      numbers: Array(4)
                        .fill(0)
                        .map(() => Math.floor(1 + Math.random() * 14)),
                      errorMsg: ''
                    };
                    list.splice(row, 1, newRow);
                    setList([...list]);
                  }}
                />
                {numbers.map((num, index) => {
                  return (
                    <div
                      className='ticket-input-container'
                      key={`number-${row}-${index}`}
                    >
                      <CornButton
                        // icon={<MetamaskIcon />}
                        className='ticket-input-button is-top'
                        type={buttonTypes.cancel}
                        size={buttonSizes.small}
                        onClick={() => {
                          numbers[index] += numbers[index] > 13 ? 0 : 1;
                          setList([...list.map((ticket) => ({ ...ticket }))]);
                        }}
                        text={'+'}
                      />
                      <input
                        className='corn-ticket-input'
                        value={num}
                        onChange={({ target: { value } }) => {
                          numbers[index] = Number.isNaN(+value)
                            ? 1
                            : +value > 14
                            ? 14
                            : +value < 1
                            ? 1
                            : +value;
                          setList([...list.map((ticket) => ({ ...ticket }))]);
                        }}
                      />
                      <CornButton
                        // icon={<MetamaskIcon />}
                        className='ticket-input-button is-bottom'
                        type={buttonTypes.cancel}
                        size={buttonSizes.small}
                        onClick={() => {
                          numbers[index] -= numbers[index] < 2 ? 0 : 1;
                          setList([...list.map((ticket) => ({ ...ticket }))]);
                        }}
                        text={'-'}
                      />
                    </div>
                  );
                })}
                <DeleteIcon
                  className='corn-ticket-delete'
                  onClick={() => {
                    list.splice(row, 1);
                    setList([...list]);
                  }}
                />
              </div>
            );
          })}
        </>
      )}
      <CornButton
        // icon={<MetamaskIcon />}
        className='corn-send-button'
        type={list.length < 1 ? buttonTypes.cancel : buttonTypes.default}
        size={buttonSizes.small}
        disabled={list.length < 1}
        onClick={onSend}
        text={'SUDMIT'}
      />
      <div className='corn-donate-container'>
        <div className='corn-donate-text'>
          If you enjoy this project, you could donate any token you want,
          thanks!
        </div>
        <CornButton
          icon={<DonateIcon className='corn-icon-donate' />}
          type={buttonTypes.cancel}
          onClick={onDonate}
          text={'Copy donation address'}
        />
      </div>
    </div>
  );
};

export default Home;
