import React from 'react';
import { useTranslation } from 'react-i18next';

// components
// import Popup from '@/components/popup';

// images
import { ReactComponent as IconSuccess } from './icon-success.svg';
import { ReactComponent as IconFail } from './icon-fail.svg';

// styles
// import './index.scss';

// generic function type
type genericFunction<P> = {
  bivarianceHack(prop: P): void;
}['bivarianceHack'];

export enum toastType {
  default = 'default',
  success = 'success',
  fail = 'fail',
}

export interface propType {
  show?: boolean;
  className?: string;
  onOverlay?: Function;
  closeOnOverlay?: boolean;
  onClose?: Function;
  type?: toastType;
  title?: string;
  content?: string[] | any;
  duration?: number;
}

export interface attachmentsType {
  // show: genericFunction<propType>;
}

const Toast: React.FC<propType> & attachmentsType = ({
  show = true,
  className,
  onOverlay,
  closeOnOverlay = true,
  onClose,
  type = toastType.default,
  title,
  content,
  duration = 2000,
}: propType) => {
  const { t } = useTranslation();

  const [showMirror, setShowMirror] = React.useState<boolean>(false);
  const overlayRef = React.useRef(null);

  const onTransitionHandler = (e: any) => {
    // const { target } = e;
    if (typeof onClose === 'function' && !showMirror) {
      onClose(e);
    }
  };

  const overlayHandler = (e: React.MouseEvent<Element, MouseEvent>) => {
    if (typeof onOverlay === 'function') {
      onOverlay(e);
    }
    if (closeOnOverlay) {
      setShowMirror(false);
    }
  };

  React.useEffect(() => {
    setTimeout(() => {
      setShowMirror(show);
    }, 0);
    setTimeout(() => {
      // showMirror && setShowMirror(false);
      setShowMirror(false);
    }, duration);
  }, []);

  return (
    <div
      className={['ng-toast-overlay', ...(showMirror ? ['show'] : ['hide'])]
        .join(' ')
        .trim()}
      onClick={overlayHandler}
      onTransitionEnd={onTransitionHandler}
      ref={overlayRef}
    >
      <div
        className={[
          'ng-toast-toast',
          ...[type],
          ...(className ? [className] : []),
        ]
          .join(' ')
          .trim()}
        onClick={(e: React.MouseEvent<Element, MouseEvent>) => {
          e.stopPropagation();
        }}
      >
        {type === toastType.success ? (
          <IconSuccess className="ng-toast-icon" />
        ) : type === toastType.fail ? (
          <IconFail className="ng-toast-icon" />
        ) : null}
        <div className="ng-toast-title">
          {title ??
            (type === toastType.success
              ? t('Message_Success') + '!'
              : type === toastType.fail
              ? t('Message_Error') + '!'
              : null)}
        </div>
        {Array.isArray(content) ? (
          content.length ? (
            <div className="ng-toast-content">
              {content?.map((text, i) => {
                return <div key={i}>{text}</div>;
              })}
            </div>
          ) : null
        ) : content ? (
          <div className="ng-toast-content">{content}</div>
        ) : null}
      </div>
    </div>
  );
};

export default Toast;
